<template>
  <div class="md:w-100 lg:w-128 md:mx-auto">
    <div class="bg-black text-white text-left md:px-4">
      <div class="flex flex-col md:flex-row px-14 md:px-3 lg:px-12">
        <div class="flex flex-col md:px-6">
          <div class="text-xl pt-24 mb-5">About Meal</div>
          <div class="text-md leading-8 text-gray-400 mb-16 md:w-56">
            The Big Oxmox advised her not to do so, because there were thousands
            of bad Commas, wild Question Marks and devious Semikoli, but the
            Little Blind Text didn’t listen.
          </div>
        </div>
        <div class="flex flex-col md:flex-row">
          <div class="flex flex-col">
            <div class="text-xl mb-5 md:pt-24">Lunch Service</div>
            <div class="text-md text-gray-400 mb-16">
              Booking from 12:00pm — 1:30pm
            </div>
            <div class="text-xl mb-5">Dinner Service</div>
            <div class="text-md text-gray-400 mb-16">
              Everyday: Booking from 6:00pm — 9:00pm
            </div>
          </div>
          <div class="flex flex-col">
            <div class="md:pt-24">Follow Along</div>
            <div class="flex py-4">
              <svgIcon
                name="facebook"
                class="h-12 w-12 mx-2 bg-white rounded-full"
              />
              <svgIcon
                name="instagram"
                class="h-12 w-12 bg-white rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center py-8 text-gray-400">
        Copyright 2022 All rights reserved | Ege
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
