<template>
  <div class="flex flex-col bg-white px-8 py-16 lg:px-16">
    <div>
      <img
        class="w-40 h-40 rounded-full mx-auto mb-8"
        src="../../assets/chef1.webp"
        alt=""
      />
    </div>
    <div class="text-2xl font-semibold mb-4">Daniel Graham</div>
    <div class="text-gray-400 mb-10">Master Chef</div>
    <div class="leading-10 text-gray-500 text-md font-light mb-6">
      Far far away, behind the word mountains, far from the countries Vokalia
      and Consonantia, there live the blind texts. Separated they live in
      Bookmarksgrove right at the coast of the Semantics, a large language
      ocean.
    </div>
    <div class="leading-10 text-gray-500 text-md font-light mb-10">
      Separated they live in Bookmarksgrove right at the coast of the Semantics,
      a large language ocean. It is a paradisematic country.
    </div>
    <div class="flex justify-center">
      <!-- <div class="px-5 text-green-600">facebook</div>
      <div class="px-5 text-green-600">instagram</div> -->
      <svgIcon
        name="facebook"
        class="h-8 w-8 mx-2 rounded-full cursor-pointer"
        :class="menuStatus ? ' z-0' : 'z-40'"
      />
      <svgIcon
        name="aaa"
        class="h-8 w-8 bg-white rounded-lg cursor-pointer"
        :class="menuStatus ? ' z-0' : 'z-40'"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
