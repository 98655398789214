<template>
  <div>
    <iframe
      class="w-full"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12496.983995252302!2d27.0971055!3d38.4588817!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9c857d204af98488!2sMakco!5e0!3m2!1str!2str!4v1639392486714!5m2!1str!2str"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
