<template>
  <div>
    <div class="bg-welcome bg-cover w-full h-screen bg-no-repeate flex align-center justify-center">
      <!-- <img  src="../../assets/arka-plan.png" alt=""> -->
      <div
        class="text-3xl flex flex-col mt-56 font-extralight md:font-bold md:text-5xl lg:text-6xl text-white"
      >
        <div class="border-2 border-white bg-black inline-block p-4 rounded-lg">
          WELCOME TO MAKCO
        </div>
        <div class="text-black text-4xl mt-16 transform translate-y-6 bg-white inline-block rounded-lg">
          EAT REAL FOOD
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.bg-welcome {
  background-image: url("../../assets/arka-plan.png");
}
</style>
